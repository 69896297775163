.pnc-rewards-tabs .pnc-rewards-tabs-title {
  text-align: left;
  margin: 0;
  padding-bottom: 46px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #333F48;
}

.pnc-rewards-tabs .nav-tabs {
  justify-content: space-evenly;
  border-bottom: 1px solid #ededee;
  padding-inline: 90px;
}

.pnc-rewards-tabs .nav-tabs .nav-item {
  flex-grow: 1;
}

.pnc-rewards-tabs .row.pnc-rewards-row {
  border: 1px solid #ededee;
  padding: 16px 5px 24px;
}

.pnc-rewards-tabs .nav-tabs .nav-link {
  background-color: transparent;
  color: #333f48;
  font-family: "PNC Sans Medium";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 6px 0px 4px;
  width: 100%;
}

.pnc-rewards-tabs .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.pnc-rewards-tabs .nav-tabs .nav-link.active {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom: 4px solid #ef6a00;
  color: #333f48;
  font-family: "PNC Sans General";
  font-weight: 700;
  outline-offset: 1px !important;
}

.pnc-rewards-tabs .nav-tabs .nav-item .nav-link:focus,
.pnc-rewards-tabs .nav-tabs li:focus-visible,
.pnc-rewards-tabs .nav-tabs li:focus {
  outline: none;
}

.pnc-rewards-tabs .nav-tabs li.nav-item .nav-link:focus-visible{
  border-radius: calc(0.25rem - 1px);
  outline: 2px solid black;
}

.pnc-rewards-tab-content {
  text-align: left;
}

.pnc-rewards-tab-desc {
  color: #2d3943;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 992px) {
  .pnc-rewards-tab-desc {
    font-size: 20px;
  }
}

p.pnc-rewards-tab-desc {
  margin-bottom: 24px;
  padding-right: 10px;
  font-family: "PNC Sans Regular";
}

.pnc-rewards-tab-content .row {
  align-items: center;
  padding-top: 34px;
}

.pnc-rewards-tabs .btn {
  border: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 70%;
  padding-inline: 5px;
  padding-block: 5.5px 7.5px;
}

.pnc-rewards-tab-btn {
  background-color: #ef6a00;
  color: #ffffff;
}

.pnc-rewards-tab-btn-2 {
  background-color: #617380;
  color: #ffffff;
  margin-top: 15px;
}

.pnc-rewards-tab-btn-2:hover,
.pnc-rewards-tab-btn-2:focus,
.pnc-rewards-tab-btn-2:focus-visible,
.pnc-rewards-tabs
  .pnc-rewards-tab-btn-2.btn-primary:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #48545d;
  border-color: #48545d;
}

.pnc-rewards-tab-btn:hover,
.pnc-rewards-tab-btn:focus,
.pnc-rewards-tab-btn:focus-visible,
.pnc-rewards-tabs .btn-primary:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #c95a00;
  border-color: #c95a00;
}

.pnc-rewards-tab-content .btn-primary:focus,
.pnc-rewards-tab-content .btn-primary:focus-visible {
  box-shadow: none;
  outline: 2px solid black;
}

.pnc-rewards-tab-img {
  border: 8px solid #ededee;
  width: 100%;
}

@media (max-width: 991px) {
  .pnc-rewards-tabs .btn-primary {
    width: 85%;
    padding-inline: 5px;
  }
}

@media (min-width: 768px) {
  .pnc-rewards-tabs.container {
    max-width: 1160px;
  }
}
