.pnc-travel-protection-wrap {
    max-width: 1160px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.protection-section {
    background-color: #f6f7f8; /* Light gray background */
    padding: 30px 0px 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pnc-protection-title {
    font-size: 36px;
    font-weight: bold;
    color: rgba(51, 63, 72, 1);
    font-family: "PNC Sans General";
}

.protection-description {
    color: rgba(45, 57, 67, 1);
    font-family: "PNC Sans Regular";
}

.pnc-protection-text-container {
    display: flex;
    flex-direction: column;
}

.pnc-protection-list {
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    padding-left: 28px;
    gap: 8px;
    color: rgba(45, 57, 67, 1);
    font-family: "PNC Sans Medium";
    font-weight: 500;
}

.pnc-protection-image {
    width: 100%;
    min-height: 371px;
    height: auto;
    overflow: hidden;
    max-width: 298px;
    margin-left: 10px;
}

.protection-image {
    max-width: 100%;
    min-height: 100%;
    width: auto; 
    object-fit: cover; 
    border: 4px solid rgba(255, 255, 255, 1);
    object-position: top center;
}

.pnc-protection-learn-more-btn {
    background-color: #EF6A00;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    width: 300px;
    font-family: "PNC Sans General";
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    padding-block: 5.5px 7.5px;
}
.pnc-protection-learn-more-btn:focus,
.pnc-protection-learn-more-btn:focus-visible{
    box-shadow: none;
    border-radius: calc(0.25rem - 1px);
  outline: 2px solid black;
}
.pnc-protection-learn-more-btn:hover,
.pnc-protection-learn-more-btn:focus-visible {
    background-color: #c95a00;
    border-color: #c95a00;
}
.pnc-protection-list-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.pnc-protection-custom-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.pnc-protection-list-content {
    display: flex;
    gap: 40px;
}
.pnc-protection-button-container {
    align-self: center;
    margin-left: 38px;
}
.pnc-desk-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
@media(min-width: 768px){
    .pnc-protection-list-content {
      margin-left: 8px;
    }
    .pnc-protection-title {
        display: inline-block;
        max-width: 150px;
      }
  }
  @media(min-width: 1065px){
    .pnc-protection-title {
      white-space: nowrap;
    }
  }
@media(min-width: 1220px){
    .pnc-protection-custom-column {
        margin-left: -44px; 
    }
}
@media(min-width: 876px) and (max-width: 1199px){
    .pnc-protection-custom-column {
        margin-left: -38px; 
    }
    .protection-section {
        padding: 30px 15px 30px 10px;
    }
}
@media(min-width: 768px) and (max-width: 875px){
    .pnc-protection-custom-column {
        margin-left: -38px; 
    }
    .protection-section {
        padding: 30px 1px 30px 10px;
    }
}
@media (max-width: 767px) {
    .pnc-travel-protection-wrap {
        flex-direction: column;
        align-items: center;
    }
    .pnc-protection-custom-column {
        padding: 0;
        margin-left: unset;
    }
    .pnc-protection-button-container {
        margin-left: 10px;
    }
    .protection-section {
        padding: 22px 0px 22px 0px
    }
    .pnc-protection-list-content {
        flex-direction: column;
        align-items: center;
    }
    .pnc-protection-list-content {
        gap: 0px;
    }
    .pnc-protection-list-container {
        margin-right: 5px;
        gap: 8px;
    }
    .pnc-protection-list {
        padding-right: 28px;
    }
    .pnc-protection-title {
        font-size: 30px;
    }

    .protection-image {
        margin-left: 0;
    }
    .pnc-protection-image {
        display: none;
    }
    .pnc-protection-learn-more-btn {
        margin-top: 8px;
        padding-inline: 0;
        width: 343px;
    }
    .pnc-desk-px-2 {
        padding-left: 0;
        padding-right: 10px;
    }
}
@media(max-width: 355px) {
    .pnc-protection-learn-more-btn { 
        width: 220px;
    }
}
@media(max-width: 240px) {
    .pnc-protection-learn-more-btn { 
        width: 120px;
    }
}