.pnc-get-rewarded {
    font-family: "PNC Sans General";
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
}
.pnc-get-rewarded-cta {
    text-decoration: underline;
    color: #333F48;
    font-size: 20px;
    font-family: "PNC Sans Medium";
    font-weight: 500;
  }
  .pnc-get-rewarded-cta:hover {
    color: #c95a00;
  }
  .pnc-get-rewarded-content-row {
    padding-bottom: 8px;
  }
  .pnc-get-rewarded .container {
    max-width: 1140px;
  }
  .pnc-get-rewarded .card {
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* max-width: 272px; */
    /* max-height: 388px; */
  }
  .pnc-get-rewarded .pnc-rewarded-card-title {
    font-size: 24px;
  }
  .pnc-get-rewarded .card-body {
    display: flex;
    flex-direction: row;
    padding: 24px 12px 17px 12px;
    column-gap: 14px;
  }
  
  .pnc-get-rewarded .card-img-top {
    padding: 6px 6px 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;              
    height: 279px;
    object-fit: cover;        
    object-position: top; 
  }
  
  .pnc-get-rewarded .card-text {
    font-size: 20px;
    line-height: 24px;
  }
  
  .pnc-get-rewarded img {
    min-height: 279px;
    object-fit: cover;
  }
  
  .pnc-get-rewarded p {
    font-family: "PNC Sans Regular";
    font-size: 22px;
    color: #333F48;
  }
  
  .pnc-get-rewarded #pnc-get-rewarded-section-heading {
    color: #333F48;
    font-size: 36px;
    font-weight: bold;
  }
  
  .pnc-get-rewarded .card-text strong {
    font-family: 'PNC Sans General';
    font-weight: bold;
    color: #ef6a00;
  }
  
  /* Flexbox Container for Cards */
  .pnc-get-rewarded .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 24px;
    max-width: 1160px;
    margin-top: -21px;
  }
  
  /* Wrapper for each card */
  .card-wrapper {
    flex: 0 1 calc(33.333% - 20px); /* 3 cards per row, with 20px gap */
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Responsive behavior */
  @media (max-width: 992px) {
    .card-wrapper {
      flex: 0 1 calc(50% - 20px); /* 2 cards per row for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .card-wrapper {
      flex: 1 1 calc(100% - 20px); /* 1 card per row for small screens */
    }
    .pnc-get-rewarded .card {
        max-width: 100%;
    }
  }
  
  @media (min-width: 992px) {
    .pnc-get-rewarded .container {
      max-width: 1160px;
      padding: 0;
    }
  }
  @media (max-width: 992px) {
    .pnc-get-rewarded .card {
        max-width: 100%;
    }
  }

  @media(max-width: 768px){
    .pnc-get-rewarded {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 2rem;
    }
    .pnc-get-rewarded-content-row {
    padding-bottom: 0;
    }
    .pnc-get-rewarded .container {
        padding-left: 0;
        padding-right: 0;
        max-width: 540px;
    }
    .pnc-get-rewarded #pnc-get-rewarded-section-heading {
        font-size: 33px;
        margin-bottom: 15px;
        line-height: 1.2;
    }
    .pnc-get-rewarded .card-wrapper {
        margin-bottom: 0px;
    }
    .pnc-get-rewarded img {
        max-height: none;
    }
    .pnc-get-rewarded .card-img-top {
        padding: 0;
    }
    .pnc-get-rewarded p {
        margin-bottom: 1rem;
    }
    .pnc-get-rewarded .card-body {
        padding: 16px 12px 12px 12px;
      }
      .pnc-get-rewarded .card-title {
        margin-bottom: 0;
    }
    .pnc-get-rewarded .cards-container {
        margin-top: 5px;
    }
}
@media (max-width: 420px){
    .pnc-get-rewarded img {
        max-height: 168px;
        min-height: auto;
        object-position: top;
    }
}