.pnc-center-promo {
    position: relative;
    background-repeat: no-repeat;
    max-width: 960px; 
    height: auto;
    aspect-ratio: 960 / 155;
    margin: 0 auto;
}
.has-background {
    display: none;
}
@media(max-width: 768px){
    .pnc-center-promo.pnc-gift-promotion {
        width: 100%;
    }
}